<template>
   <div class="page">
      
    <!-- 正面是文字，反面是图片 -->
   <div class="card card-left">
      <div class="font">
        <div class="gest">
        <img src="../assets/images/wt28.png"></div> 
      
      <div class="img">
       <img src="../assets/images/wt50.jpg"></div></div>
      <div class="back">
        <div class="gest">
        <img src="../assets/images/wt27.png"></div>
        <div class="pho">
            <img src="../assets/images/wt44.png">
          </div>
        <div class="title">习近平新时代中国特色社会主义思想</div>
        <ul>
        <h2>（一）</h2>

<p>明确中国特色社会主义最本质的特征是中国共产党领导，中国特色社会主义制度的最大优势是中国共产党领导，中国共产党是最高政治领导力量，全党必须增强“四个意识”、坚定“四个自信”、做到“两个维护”；</p>

<h2>（二）</h2>

<p>明确坚持和发展中国特色社会主义，总任务是实现社会主义现代化和中华民族伟大复兴，在全面建成小康社会的基础上，分两步走在本世纪中叶建成富强民主文明和谐美丽的社会主义现代化强国，以中国式现代化推进中华民族伟大复兴；</p>

<h2>（三）</h2>

<p>明确新时代我国社会主要矛盾是人民日益增长的美好生活需要和不平衡不充分的发展之间的矛盾，必须坚持以人民为中心的发展思想，发展全过程人民民主，推动人的全面发展、全体人民共同富裕取得更为明显的实质性进展；</p>

<h2>（四）</h2>
<p>明确中国特色社会主义事业总体布局是经济建设、政治建设、文化建设、社会建设、生态文明建设五位一体，战略布局是全面建设社会主义现代化国家、全面深化改革、全面依法治国、全面从严治党四个全面；</p>
<h2>（五）</h2>
<p>明确全面深化改革总目标是完善和发展中国特色社会主义制度、推进国家治理体系和治理能力现代化；</p>
<h2>（六）</h2>

<p>时代在变化，社会在发展，但马克思主义基本原理依然是科学真理。尽管我们所处的时代同马克思所处的时代相比发生了巨大而深刻的变化，但从世界社会主义500年的大视野来看，我们依然处在马克思主义所指明的历史时代。这是我们对马克思主义保持坚定信心、对社会主义保持必胜信念的科学根据。马克思主义就是我们党和人民事业不断发展的参天大树之根本，就是我们党和人民不断奋进的万里长河之泉源。背离或放弃马克思主义，我们党就会失去灵魂、迷失方向。在坚持以马克思主义为指导这一根本问题上，我们必须坚定不移，任何时候任何情况下都不能动摇。</p>

</ul>
<div class="pag">1/20</div>
      </div>
      
    </div>
    <div class="card">
      <div class="tria1"></div>
    <div class="tria2"></div>
      <div class="font">
        <div class="gest">
        <img src="../assets/images/wt28.png"></div> 
        <ul>
  <h2>（七）</h2>
  <p>明确必须坚持和完善社会主义基本经济制度，使市场在资源配置中起决定性作用，更好发挥政府作用，把握新发展阶段，贯彻创新、协调、绿色、开放、共享的新发展理念，加快构建以国内大循环为主体、国内国际双循环相互促进的新发展格局，推动高质量发展，统筹发展和安全；</p>
<h2>（八）</h2>
<p>明确党在新时代的强军目标是建设一支听党指挥、能打胜仗、作风优良的人民军队，把人民军队建设成为世界一流军队；</p>
<h2>（九）</h2>
<p>明确中国特色大国外交要服务民族复兴、促进人类进步，推动建设新型国际关系，推动构建人类命运共同体；</p>
<h2>（十）</h2>
<p>明确全面从严治党的战略方针，提出新时代党的建设总要求，全面推进党的政治建设、思想建设、组织建设、作风建设、纪律建设，把制度建设贯穿其中，深入推进反腐败斗争，落实管党治党政治责任，以伟大自我革命引领伟大社会革命。</p>
<div class="img">
            <img src="../assets/images/wt30.jpg">
          </div>
<div class="pag">2/20</div>
</ul>
</div>
      <div class="back">
        <div class="gest">
        <img src="../assets/images/wt27.png"></div>
        <ul>
          <div class="pho">
            <img src="../assets/images/wt44.png">
          </div>
          <div class="title">习近平：在复兴之路上坚定前行——《复兴文库》序言</div>
          
<div class="img1">
            <img src="../assets/images/wt36.jpg">
          </div>
<p>修史立典，存史启智，以文化人，这是中华民族延续几千年的一个传统。编纂《复兴文库》，是党中央批准实施的重大文化工程。在我们党带领人民迈上全面建设社会主义现代化国家新征程之际，这部典籍的出版，对于我们坚定历史自信、把握时代大势、走好中国道路，以中国式现代化推进中华民族伟大复兴具有十分重要的意义。</p>

<p>中华民族是世界上伟大的民族，为人类文明进步作出了不可磨灭的贡献。近代以后，中华民族遭受了前所未有的劫难。从那时起，实现中华民族伟大复兴就成为中国人民和中华民族最伟大的梦想。无数仁人志士矢志不渝、上下求索，奔走呐喊、奋起抗争。中国共产党成立后，团结带领人民前仆后继，进行艰苦卓绝的斗争，坚持马克思主义指导地位，找到了实现中华民族伟大复兴的正确道路，通过革命、建设、改革各个历史时期的不懈努力，迎来了从站起来、富起来到强起来的伟大飞跃，谱写了中华民族发展进程中最为波澜壮阔的历史篇章，中华民族伟大复兴展现出前所未有的光明前景。历史已经证明并将继续证明，在中国共产党坚强领导下，坚持科学理论指导和正确道路指引，凝聚亿万人民团结奋斗的磅礴力量，中国人民就能把中国发展进步的命运牢牢掌握在自己手中！</p>






        </ul>
        <div class="pag">3/20</div>
      </div>
    </div>
    <div class="card">
      <div class="font">
        <div class="gest">
        <img src="../assets/images/wt28.png">
        </div>
        <ul>
        <p>在实现伟大复兴的历史进程中，一代代中华民族的先进分子和优秀儿女探索、奋斗、牺牲、创造，留下了大量具有重要历史价值和时代意义的珍贵文献。编纂出版《复兴文库》大型历史文献丛书，就是要通过对近代以来重要思想文献的选编，述录先人的开拓，启迪来者的奋斗。</p>
<p>历史是最好的教科书，一切向前走，都不能忘记走过的路；走得再远、走到再光辉的未来，也不能忘记走过的过去。当前，世界百年未有之大变局加速演进，中华民族伟大复兴进入关键时期，我们更需要以史为鉴、察往知来。</p>

<p>我们要在学好党史的基础上，学好中国近代史，学好中国历史，弄清楚我们从哪里来、要到哪里去，弄清楚中国共产党人是干什么的、已经干了什么、还要干什么，弄清楚过去我们为什么能够成功、未来怎样才能继续成功。要坚定文化自信、增强文化自觉，传承革命文化、发展社会主义先进文化，推动中华优秀传统文化创造性转化、创新性发展，构筑中华民族共有精神家园。要萃取历史精华，推动理论创新，更好繁荣中国学术、发展中国理论、传播中国思想，不断推进马克思主义中国化时代化。要坚定理想信念，凝聚精神力量，在新时代更好坚持和发展中国特色社会主义，为实现中华民族伟大复兴的中国梦贡献我们这一代人的智慧和力量，创造属于我们这一代人的业绩和荣光。</p>
<div class="img2">
            <img src="../assets/images/wt35.jpg">
          </div>
</ul> 
        <div class="pag">4/20</div>
        </div>
      <div class="tria1"></div>
    <div class="tria2"></div>
      <div class="back">
        <div class="gest">
        <img src="../assets/images/wt27.png"></div>
        <div class="title">科教兴国</div>
        <ul>
          <div class="pho">
            <img src="../assets/images/wt44.png">
          </div>
        <p>不久前，中国载人航天工程办公室发布消息，神舟十五号航天员乘组状态良好，计划于今年6月返回地面，我国载人航天工程全面进入空间站应用与发展新阶段。据悉，2023年中国航天工程计划实施近70次发射任务，开启高质量、高效率、高效益的发展新征程。航天探索永无止境，奏响建设世界科技强国的时代强音，也彰显出科教兴国战略积厚成势的雄浑力量。</p>

<p>中国综合实力要强，中国人民生活要好，必须有强大科技。从新中国成立后吹响“向科学进军”的号角，到改革开放提出“科学技术是第一生产力”的论断，从进入新世纪深入实施知识创新工程、科教兴国战略、人才强国战略，到党的十八大后提出创新是第一动力、全面实施创新驱动发展战略、建设世界科技强国，在各个历史时期，我们党都高度重视科技事业。新时代新征程，新形势新任务，要求我国在科技创新方面有新理念、新设计、新战略。党的二十大报告将“实施科教兴国战略，强化现代化建设人才支撑”列为一个单独部分进行具体部署，对优化我国科技事业发展总体布局、实现高水平科技自立自强，对办好人民满意的教育、着力造就拔尖创新人才等都具有深远影响。</p>

<p>科教兴国的根本在人才，人才培养归根结底要靠教育。新时代十年来，我国建成世界上规模最大的教育体系，教育普及水平实现历史性跨越。其中，义务教育和学前教育的普及程度达到高收入国家平均水平，高等教育进入国际社会公认的普及化阶段，每年新增劳动力的平均受教育年限达14年，为如期全面建成小康社会、实现第一个百年奋斗目标提供了重要支撑。深入实施科教兴国战略，始终把教育摆在优先发展的战略位置，全面提高人才自主培养质量，加快建设高质量教育体系，让广大人民享有更好更公平的教育，才能为全面建设社会主义现代化国家提供更坚实的人才支持和智力支撑。</p>
<div class="img3">
            <img src="../assets/images/wt38.jpg">
          </div>

</ul>
        <div class="pag">5/20</div>
      </div>
    </div>
    <div class="card">
      <div class="font"><div class="gest">
        <img src="../assets/images/wt28.png"></div>
        <ul>
        <p>科技是第一生产力，科技从来没有像今天这样深刻影响着国家前途命运和人民生活福祉。回看过去一年，中国空间站全面建成，第三艘航母“福建号”下水，首架C919大飞机正式交付，科技成就硕果累累。回看过去十年，全社会研发经费支出从2012年的1万亿元增加到2022年的3.09万亿元，研发人员总量跃居世界首位，科技创新已成为我国经济高质量发展、综合国力大幅度增强、国际竞争力显著提升的重要因素。深入实施科教兴国战略，必须不断完善科技创新体系，坚持创新在我国现代化建设全局中的核心地位，强化国家战略科技力量，努力在若干重要领域形成竞争优势、赢得战略主动。</p>

<p>全面建设社会主义现代化国家，科技是关键，人才是基础，教育是根本。三者协同配合、系统集成，才能为全面建设社会主义现代化国家筑牢基础性、战略性支撑。习近平总书记在中共中央政治局第二次集体学习时要求，“实现科教兴国战略、人才强国战略、创新驱动发展战略有效联动”。我们必须准确把握坚持教育优先发展、科技自立自强、人才引领驱动的相互关系，坚持教育发展、科技创新、人才培养一体推进，形成良性循环；坚持原始创新、集成创新、开放创新一体设计，实现有效贯通；坚持创新链、产业链、人才链一体部署，推动深度融合。</p>

<p>今天，科教兴国战略给我国科技事业发展提供强大驱动力。奋斗健儿在干事创业中抢抓机遇，有志之士在广阔舞台上大展作为，定能不断谱写深入实施科教兴国战略的新篇章。</p>
          <div class="img4">
            <img src="../assets/images/wt37.png">
          </div> 
          </ul>
        <div class="pag">6/20</div>
        </div>
      <div class="tria1"></div>
    <div class="tria2"></div>
      <div class="back">
        <div class="gest">
        <img src="../assets/images/wt27.png"></div>
        <div class="title">经济建设</div>
        <ul>
          <div class="pho">
            <img src="../assets/images/wt44.png">
          </div>
        <p>要搞好统筹扩大内需和深化供给侧结构性改革，形成需求牵引供给、供给创造需求的更高水平动态平衡，实现国民经济良性循环。坚决贯彻落实扩大内需战略规划纲要，尽快形成完整内需体系，着力扩大有收入支撑的消费需求、有合理回报的投资需求、有本金和债务约束的金融需求。建立和完善扩大居民消费的长效机制，使居民有稳定收入能消费、没有后顾之忧敢消费、消费环境优获得感强愿消费。完善扩大投资机制，拓展有效投资空间，适度超前部署新型基础设施建设，扩大高技术产业和战略性新兴产业投资，持续激发民间投资活力。继续深化供给侧结构性改革，持续推动科技创新、制度创新，突破供给约束堵点、卡点、脆弱点，增强产业链供应链的竞争力和安全性，以自主可控、高质量的供给适应满足现有需求，创造引领新的需求。
</p>

<p>新发展格局以现代化产业体系为基础，经济循环畅通需要各产业有序链接、高效畅通。要继续把发展经济的着力点放在实体经济上，扎实推进新型工业化，加快建设制造强国、质量强国、网络强国、数字中国，打造具有国际竞争力的数字产业集群。顺应产业发展大势，推动短板产业补链、优势产业延链，传统产业升链、新兴产业建链，增强产业发展的接续性和竞争力。优化生产力布局，推动重点产业在国内外有序转移，支持企业深度参与全球产业分工和合作，促进内外产业深度融合，打造自主可控、安全可靠、竞争力强的现代化产业体系。</p>

<div class="img5">
            <img src="../assets/images/wt40.png">
          </div> 
            
          

</ul>
        <div class="pag">7/20</div>
      </div>
    </div>
    <div class="card">
      <div class="font"><div class="gest">
        <img src="../assets/images/wt28.png"></div>
        <ul>
<p>要全面推进城乡、区域协调发展，提高国内大循环的覆盖面。充分发挥乡村作为消费市场和要素市场的重要作用，全面推进乡村振兴，推进以县城为重要载体的城镇化建设，推动城乡融合发展，增强城乡经济联系，畅通城乡经济循环。防止各地搞自我小循环，打消区域壁垒，真正形成全国统一大市场。推动区域协调发展战略、区域重大战略、主体功能区战略等深度融合，优化重大生产力布局，促进各类要素合理流动和高效集聚，畅通国内大循环。</p>
<p>要进一步深化改革开放，增强国内外大循环的动力和活力。深化要素市场化改革，建设高标准市场体系，加快构建全国统一大市场。完善产权保护、市场准入、公平竞争、社会信用等市场经济基础制度，加强反垄断和反不正当竞争，依法规范和引导资本健康发展，为各类经营主体投资创业营造良好环境，激发各类经营主体活力。推进高水平对外开放，稳步推动规则、规制、管理、标准等制度型开放，增强在国际大循环中的话语权。推动共建“一带一路”高质量发展，积极参与国际经贸规则谈判，推动形成开放、多元、稳定的世界经济秩序，为实现国内国际两个市场两种资源联动循环创造条件。</p>
</ul>
          <div class="img5">
            <img src="../assets/images/wt41.jpg">
          </div> 
<div class="pag">8/20</div></div>
      <div class="tria1"></div>
    <div class="tria2"></div>
      <div class="back">
        <div class="gest">
        <img src="../assets/images/wt27.png"></div>
        <div class="title">坚持科技强军 推进核心战斗力生成</div>
<ul>
  <div class="pho">
            <img src="../assets/images/wt44.png">
          </div>
<h2>强军征程上，习近平主席作出科技是现代战争的核心战斗力的重大论断，推动我军向质量效能型和科技密集型转变。</h2>

<p>作为军队基本的战术单元、科技运用的末梢和战场感知的触角，基层部队必须坚持科技强军，转变大数据背景下遂行任务观念，推动新型战斗力加速融入，更新信息化军事人才培养机制，进一步深刻理解“科技是现代战争的核心战斗力”的重大意义和实践要求，持续推进核心战斗力生成走深走实。</p>

<h2>转变大数据背景下用兵指挥理念</h2>

<p>当前，将大数据充分运用于军事领域成为世界主要军事强国作战指挥体系转型建设发展的方向。大数据结构扁平、层次简洁、高度集中、体系融合的组织形态，成为现代战争中智慧战场的战略新资源。</p>

<p>传统的用兵指挥理念已难以适应信息化条件下遂行多样化任务的需求。指挥员要实现快速精确指挥，必须摆脱以往“重经验轻数据”的认识。不善于依托指挥信息系统进行辅助决策，就会对敌情、我情、社情、天情、地情等看不清、算不准。指挥员必须熟练掌握信息化基础知识，提升用数据来说话、用数据来决策、用数据来打仗的能力素养。</p>

<p>当前，军事智能化程度正在逐步加深，战场智能化作战特点愈发凸显。要充分发挥军事大数据海量、高速、真实，可融合、能分析的突出优势，融入科学训练方法，依托模拟环境构造、人体工程学理论等技术，打造出一批科技成果，不断提升信息化条件下军事训练水平。要提升基于大数据的数据分析、辅助决策、作战一体化能力，使指挥决策更加快速、精确。</p>
<div class="img5">
            <img src="../assets/images/wt29.jpg">
          </div> 
<div class="img6">
            <img src="../assets/images/wt48.jpg">
          </div> 
          <div class="img7">
            <img src="../assets/images/wt49.jpg">
          </div> 


  </ul> 
        <div class="pag">9/20</div>
      </div>
    </div>
    <div class="card">
      <div class="font"><div class="gest">
        <img src="../assets/images/wt28.png"></div> 
        <ul>
          <h2>推动新型作战力量加速融入</h2>

<p>新型作战力量将是未来信息化战场上的主力军和杀手锏。其有着独特的制胜机理、崭新的作战方式和超常的作战效能。</p>

<p>新型作战力量建设与传统军事力量建设的最大不同，就是没有惯例可循。因此，应把握其本质特性和内在规律，并将这种特征和规律外化为训练实践，提升作战能力和保障能力。</p>

<p>在实战化演训中，新型作战力量与传统作战力量间还存在软件难兼容、数据难共享、信息难联通、系统难融合的情况。为此，要通过信息技术的“黏合”作用，实现不同类型作战单元的“无形合成”，实现多要素集成、多系统融合、多层次衔接，真正将新型作战力量的指挥平台、武器装备融入联合作战体系当中。</p>

<p>新型作战力量与传统作战力量相比，作战平台更复杂，战场行动涉及的调控关系更多，对部队组训和指挥员素质的要求更高。因此，要利用其新型作战特点，在与强敌“过招”中不断提升人员实战能力，积极研究作战问题、打磨战备方案、练熟应急流程、复盘总结提高，持续优化新型作战力量体系建设，在实战检验中实现能力迭代升级，实现“战”“训”“研”的相互促进。</p>
        <h2>更新军事人才培养机制</h2>

<p>军事领域的较量归根结底是人才的竞争。信息时代，军事变革的速度更快，军事人才在军事变革中的主体作用更突出。</p>

<p>要加强锻炼新型作战力量干部的指挥决策能力，加大交叉培训和交叉任职力度，把部队的指挥员培养成思维先进、科技素养扎实的复合型人才；把应用型人才作为基层部队的主要组成部分；鼓励现有人员进行在岗自学、职业技能培训或进入院校深造，增强他们的军事指挥和专业技术能力，提高其运用信息化装备的能力，努力实现人装最佳结合。</p>

<p>要着眼信息化建设和体系作战，把人才建设和不同层次、不同岗位的特殊需求相结合，细化目标要求、改进培养模式、明确职责分工，实施有针对性的分类指导，进一步增强人员职业技能，提高驾驭未来新型作战力量的能力。</p>

<p>要以改进军事训练考评为抓手，以突出联合作战指挥能力为重点，科学确定军事训练考评内容，在联训联演中培养军事人才在新型作战力量中组织指挥、协同作战、快速反应和应急处置的能力，不断更新新型军事人才群体知识结构，使其成为适应信息化建设指技结合的复合型人才。
  </p>
  </ul>
  <div class="pag">10/20</div></div>
      <div class="tria1"></div>
    <div class="tria2"></div>
      <div class="back">
        <div class="gest">
        <img src="../assets/images/wt27.png"></div>
        <div class="title">加快建设农业强国 推进农业农村现代化</div>
<ul>
  <div class="pho">
            <img src="../assets/images/wt44.png">
          </div>
<p>
这次中央农村工作会议的主要任务是：全面贯彻落实党的二十大精神，总结今年“三农”工作，研究部署明年工作，就加快建设农业强国进行战略部署。</p>

<p>党的二十大闭幕后，我第一次外出考察去了陕西延安、河南安阳看乡村振兴，一路思考在全面建设社会主义现代化国家新征程上如何加快建设农业强国、推进农业农村现代化。这次会议我来讲讲，就是表明党中央加强“三农”工作的鲜明态度，发出重农强农的强烈信号。下面，我就加快建设农业强国、加强“三农”工作讲几点意见。</p>

<h2>一、锚定建设农业强国目标，切实抓好农业农村工作</h2>

<p>党的二十大在擘画全面建成社会主义现代化强国宏伟蓝图时，对农业农村工作进行了总体部署。概括地讲：未来5年“三农”工作要全面推进乡村振兴，到2035年基本实现农业现代化，到本世纪中叶建成农业强国。这是党中央着眼全面建成社会主义现代化强国作出的战略部署。强国必先强农，农强方能国强。没有农业强国就没有整个现代化强国；没有农业农村现代化，社会主义现代化就是不全面的。我们必须深刻领会党中央这一战略部署，把加快建设农业强国摆上建设社会主义现代化强国的重要位置。</p>

<p>农业强国是社会主义现代化强国的根基。农业是基础，基础不牢大厦不稳。无论社会现代化程度有多高，14亿多人口的粮食和重要农产品稳定供给始终是头等大事。满足人民美好生活需要，离不开农业发展。全面建设社会主义现代化国家，出发点和落脚点是让人民生活越过越好。现代化越往前走、物质生活越丰富，人民群众越喜欢山清水秀的田园风光，农业除了保障粮食和重要农产品供给，其生态涵养、休闲观光、文化传承的功能就越能发挥积极作用。农业是近两亿人就业的产业，农村是近5亿农民常住的家园，只有把农业农村搞好了，广大农民安居乐业，他们才有充足的获得感、幸福感、安全感。实现高质量发展，也离不开农业发展。只有农业强了，农产品供给有保障，物价稳定、人心安定，经济大局才能稳住。拓展现代化发展空间，农业农村是大有可为的广阔天地。几亿农民整体迈入现代化，会释放巨大的创造动能和消费潜能，为经济社会发展注入强大动力。畅通工农城乡循环，是畅通国内经济大循环、增强我国经济韧性和战略纵深的重要方面。扩内需、稳增长、促发展，农业强国建设是重要的发力点和突破口。农业还是国家安全的基础。农业保的是生命安全、生存安全，是极端重要的国家安全。当今世界，百年未有之大变局加速演进，新冠疫情反复延宕，来自外部的打压遏制不断升级，各种不确定难预料因素明显增多。一旦农业出问题，饭碗被人拿住，看别人脸色吃饭，还谈什么现代化建设？只有农业强起来，粮食安全有完全保</p>




</ul>






        <div class="pag">11/20</div>
      </div>
    </div>
    <div class="card">
      <div class="font"><div class="gest">
        <img src="../assets/images/wt28.png"></div> 
        <ul>
        障，我们稳大局、应变局、开新局才有充足底气和战略主动。<p>现在，农业农村仍然是我国现代化建设的短板。进入新时代，党中央坚持高度重视“三农”工作的传统，在新中国成立以来特别是改革开放以来工作的基础上，通过开展脱贫攻坚、实施乡村振兴战略等，用有限资源稳定解决14亿多人口的吃饭问题，全体农民摆脱绝对贫困、同步进入全面小康，“三农”工作成就巨大、举世公认。同时，受制于人均资源不足、底子薄、历史欠账较多等原因，“三农”仍然是一个薄弱环节，同新型工业化、信息化、城镇化相比，农业现代化明显滞后。主要表现在：农业生产效率相对较低，农业劳动生产率仅为非农产业的25.3%；农业比较效益低下；农产品国际竞争力明显不足，国内粮食等农产品价格普遍超过国际市场；农村基础设施和公共服务落后于城市；城乡居民收入比为2.5∶1、消费支出比为1.9∶1。这是党中央强调全面推进乡村振兴、加快建设农业强国的一个重要原因。</p>

<p>建设农业强国，基本要求是实现农业现代化。我们要建设的农业强国、实现的农业现代化，既有国外一般现代化农业强国的共同特征，更有基于自己国情的中国特色。所谓共同特征，就是要遵循农业现代化一般规律，建设供给保障强、科技装备强、经营体系强、产业韧性强、竞争能力强的农业强国。所谓中国特色，就是立足我国国情，立足人多地少的资源禀赋、农耕文明的历史底蕴、人与自然和谐共生的时代要求，走自己的路，不简单照搬国外现代化农业强国模式。</p>



<p>农业强国的中国特色，我看主要应该包括以下几个方面：一是依靠自己力量端牢饭碗。14亿多人口的中国，任何时候都必须自力更生保自己的饭碗，坚持产量产能一起抓、数量质量一起抓、生产生态一起抓，增强农业产业链供应链韧性和稳定性。二是依托双层经营体制发展农业。立足小农数量众多的基本农情，以家庭经营为基础，坚持统分结合，广泛开展面向小农的社会化服务，积极培育新型农业经营主体，形成中国特色的农业适度规模经营。三是发展生态低碳农业。坚持绿色是农业的底色、生态是农业的底盘。必须摒弃竭泽而渔、焚薮而田、大水大肥、大拆大建的老路子，实现农业生产、农村建设、乡村生活生态良性循环，生态农业、低碳乡村成为现实，做到资源节约、环境友好，守住绿水青山。四是赓续农耕文明。我国拥有灿烂悠久的农耕文明，必须确保其根脉生生不息，做到乡村社会形态完整有效，文化基因、美好品德传承弘扬，农耕文明和城市文明交相辉映，物质文明和精神文明协调发展，广大农民自信自强、振奋昂扬，精神力量充盈。五是扎实推进共同富裕。实现城乡融合发展、基本公共服务均等化，农村具备现代生活条件，农民全面发展、过上更加富裕更加美好的生活。同时，我们建设农业强国，也是在为全球可持续发展、消除贫困贡献中国力量。</p>


</ul>
<div class="pag">12/20</div></div>
      <div class="tria1"></div>
    <div class="tria2"></div>
      <div class="back">
        <div class="gest">
        <img src="../assets/images/wt27.png"></div>
        <ul>
          <div class="pho">
            <img src="../assets/images/wt44.png">
          </div>
        <p>加快建设农业强国是新时代新征程我们党做好“三农”工作的战略部署，必须保持战略定力、久久为功。当前，要锚定建设农业强国目标，科学谋划和推进“三农”工作。一要加强顶层设计。制定加快建设农业强国规划，针对未来5年、2035年、本世纪中叶的目标，分别制定路线图和施工图，强化规划的法规效力。制定规划要同现有规划相衔接，保持工作连续性，不能“翻烧饼”。要严格执行规划，坚决防止不把规划当回事。二要循序渐进、稳扎稳打。建设农业强国是一项长期而艰巨的历史任务，要分阶段扎实稳步推进，以钉钉子精神锲而不舍干下去。当前要把重点放在全面推进乡村振兴上，多做打基础、利长远的事情。三要因地制宜、注重实效。各地要立足资源禀赋和发展阶段，发挥自身优势，服务大局需要，作出应有贡献，从本地农业农村发展最迫切、农民反映最强烈的实际问题入手，充分调动农民群众的积极性、主动性、创造性，办一项是一项、办一件成一件，不要一股脑去搞脱离实际的“高大上”或面子工程的东西。</p>
        <h2>二、保障粮食和重要农产品稳定安全供给始终是建设农业强国的头等大事</h2>

<p>无农不稳，无粮则乱。这一幕在古今中外反复上演。历史上，齐国诱导鲁国弃粮种桑，关键时候断粮降服鲁国；越王勾践把种子煮熟贡给吴国，趁其粮食绝收一举灭吴。今年乌克兰危机爆发以来，全球30多个国家限制粮食出口，部分国家因此社会动荡甚至政权更迭。只有把牢粮食安全主动权，才能把稳强国复兴主动权。</p>

<p>农业强，首要是粮食和重要农产品供给保障能力必须强。这些年，我们依靠自己的力量端稳中国饭碗，14亿多人吃饱吃好。现在，粮食需求刚性增长，端牢饭碗的压力大。必须全方位夯实粮食安全根基，既要抓物质基础，强化藏粮于地、藏粮于技，也要抓机制保障，做到产能提升、结构优化、韧性增强、收益保障、责任压实。</p>

<p>提升粮食产能仍然是首要任务。我国粮食产量已经连续8年保持在1.3万亿斤高平台上，越往前走难度越大。要实施新一轮千亿斤粮食产能提升行动，抓紧制定实施方案，把任务落实下去。提产能关键还是抓耕地和种子两个要害。要坚决守住18亿亩耕地红线，坚决遏制“非农化”、有效防止“非粮化”。坚持良田粮用大原则，良田好土要优先保粮食，果树苗木尽量上山上坡，蔬菜园艺更多靠设施农业和工厂化种植。治理“非粮化”政策性很强，要统筹考虑粮食生产和重要农产品保障、农民增收的关系，留出一定过渡期，加强政策引导。要逐步把永久基本农田全部建成高标准农田，尽快提出落实办法，该拿的钱要拿到位，建设质量和管护机制也要到位，确保建一块成一块。抓种子要选准突破口，持续发力、协同攻关，把种业振兴行动切实抓出成效，把当家品种牢牢攥在自己手里。生物育种是大方向，要加快产业化步伐。</p>


</ul>
        <div class="pag">13/20</div>
      </div>
    </div>
    <div class="card">
      <div class="font"><div class="gest">
        <img src="../assets/images/wt28.png"></div> 
        <ul>
        <p>确保粮食安全，不仅要着眼平时，还要提升应急保供能力，系统梳理生产、加工、流通、储备、贸易等方面可能存在的风险点。</p>

<p>农民种粮能挣钱，粮食生产才有保障。要健全种粮农民收益保障机制，完善价格、补贴、保险“三位一体”的政策体系，完善农资保供稳价应对机制，稳定农民预期、降低生产风险。要创新粮食生产经营方式，延伸产业链条，实现节本增效。要出实招健全主产区利益补偿机制，探索产销区多渠道利益补偿办法。既不能让种粮农民在经济上吃亏，也不能让种粮大县在财政上吃亏。</p>

<p>保障粮食安全，要在增产和减损两端同时发力。当前，食物采收、储运、加工、销售、消费每个环节都有“跑冒滴漏”，情况还相当严重。据有关机构估算，每年损失浪费的食物超过22.7%，约9200亿斤，若能挽回一半的损失，就够1.9亿人吃一年。消费环节大有文章可做，不仅要制止“舌尖上的浪费”，深入开展“光盘行动”，还要提倡健康饮食。我国居民食用油和“红肉”人均消费量，分别超过膳食指南推荐标准约1倍和2倍。食物节约减损既可有效减轻供给压力，也可减少资源使用，善莫大焉。要树立节约减损就是增产的理念，推进全链条节约减损，健全常态化、长效化工作机制，每个环节都要有具体抓手，越是损失浪费严重的环节越要抓得实。要持续深化食物节约各项行动，强化刚性约束，加大工作力度，不弃微末、不舍寸功，尽快取得更大成效。</p>



<p>解决吃饭问题，不能光盯着有限的耕地，要把思路打开，树立大食物观。“吃饭”不仅仅是消费粮食，肉蛋奶、果菜鱼、菌菇笋等样样都是美食。耕地以外，我国还有40多亿亩林地、近40亿亩草地和大量的江河湖海等资源。要构建多元化食物供给体系，在保护好生态环境前提下，从耕地资源向整个国土资源拓展，从传统农作物和畜禽资源向更丰富的生物资源拓展，向森林、草原、江河湖海要食物，向植物动物微生物要热量、要蛋白，多途径开发食物来源。设施农业大有可为，要发展日光温室、植物工厂和集约化畜禽养殖，推进陆基和深远海养殖渔场建设，拓宽农业生产空间领域。</p>

<p>在保障粮食安全的同时，必须保证其他重要农产品稳定安全供给，特别是抓好大豆和油料生产，抓好生猪和“菜篮子”工程。这些都作了部署，关键是扎扎实实抓落实。</p>

<p>各级党委和政府务必把粮食安全这一“国之大者”扛在肩头。衡量一个地方领导干部得不得力、合不合格，可不仅仅看GDP、能不能抓上几个项目，更主要看是否按照党中央要求，完整、准确、全面贯彻新发展理念，局部服从整体，把粮食安全这类党中央交办的大事要事办好办妥。粮食安全党政同责要求很明确，现在关键是要严格考核，督促各地真正把责任扛起来。</p>
</ul>
<div class="pag">14/20</div></div>
      <div class="tria1"></div>
    <div class="tria2"></div>
      <div class="back">
        <div class="gest">
        <img src="../assets/images/wt27.png"></div>
        <ul>
          <div class="pho">
            <img src="../assets/images/wt44.png">
          </div>
        <h2>三、全面推进乡村振兴是新时代建设农业强国的重要任务</h2>

<p>建设农业强国，当前要抓好乡村振兴。“三农”工作重心已经实现历史性转移，人力投入、物力配置、财力保障都要转移到乡村振兴上来。这件事刚刚破题，更为艰巨繁重的任务还在后面，决不能松劲歇脚，更不能换频道。总的要求仍然是全面推进产业、人才、文化、生态、组织“五个振兴”。“五个振兴”是相互联系、相互支撑、相互促进的有机统一整体，要统筹部署、协同推进，抓住重点、补齐短板，还要强调精准、因地制宜，激发乘数效应和化学反应，提高全面推进乡村振兴的效力效能。</p>

<p>产业振兴是乡村振兴的重中之重，也是实际工作的切入点。没有产业的农村，难聚人气，更谈不上留住人才，农民增收路子拓不宽，文化活动很难开展起来。各地推动产业振兴，要把“土特产”这3个字琢磨透。“土”讲的是基于一方水土，开发乡土资源。要善于分析新的市场环境、新的技术条件，用好新的营销手段，打开视野来用好当地资源，注重开发农业产业新功能、农村生态新价值，如发展生态旅游、民俗文化、休闲观光等。“特”讲的是突出地域特点，体现当地风情。要跳出本地看本地，打造为广大消费者所认可、能形成竞争优势的特色，如因地制宜打造苹果村、木耳乡、黄花镇等。“产”讲的是真正建成产业、形成集群。要延长农产品产业链，发展农产品加工、保鲜储藏、运输销售等，形成一定规模，把农产品增值收益留在农村、留给农民。产业梯度转移是个趋势，各地发展特色产业时要抓住这个机遇。总之，要依托农业农村特色资源，向开发农业多种功能、挖掘乡村多元价值要效益，向一二三产业融合发展要效益，强龙头、补链条、兴业态、树品牌，推动乡村产业全链条升级，增强市场竞争力和可持续发展能力。</p>

<p>促进产业振兴，必须落实产业帮扶政策。最近，审计署开展了国家乡村振兴重点帮扶县产业帮扶政策落实和资金审计。审计结果表明，部分地区还存在产业发展基础不牢、联农带农机制不够持续稳固、产业配套支持政策落实不够精准有效等问题，不利于特色产业发展壮大和脱贫群众稳定增收。各地区各部门要严格贯彻落实乡村振兴责任制，针对存在的问题，采取有效举措抓整改，扎扎实实推进乡村振兴各项任务落实。</p>


</ul>
        <div class="pag">15/20</div>
      </div>
    </div>
    <div class="card">
      <div class="font"><div class="gest">
        <img src="../assets/images/wt28.png"></div> 
        <ul>
          <p>巩固拓展脱贫攻坚成果是全面推进乡村振兴的底线任务。当前，受新冠疫情、国内国际经济下行等因素影响，巩固拓展脱贫攻坚成果遇到一些新情况。要继续压紧压实责任，发挥好防止返贫监测帮扶机制预警响应作用，把脱贫人口和脱贫地区的帮扶政策衔接好、措施落到位，实现平稳过渡，坚决防止出现整村整乡返贫现象。要更多在增强脱贫地区和脱贫群众内生发展动力上下功夫，把增加脱贫群众收入作为主攻方向，在促进脱贫地区加快发展上多想办法，推动各类资源、帮扶措施向促进产业发展和扩大就业聚焦聚力。要注重激发脱贫群众依靠自身力量发展的志气心气底气，让勤劳致富的受激励，防止“养懒汉”。鼓励实行发展类的补贴，要倡导多干多补、少干少补、不干不补。鼓励实施建设类的项目，也要分清什么事该政府干、什么事该农民干，再也不要干那种干部干、农民看的事情。现在，5年过渡期已过两年，要谋划过渡期后的具体制度安排，推动防止返贫帮扶政策和农村低收入人口常态化帮扶政策衔接并轨，把符合条件的对象全部纳入常态化帮扶，研究建立欠发达地区常态化帮扶机制。应该由政策兜底帮扶的脱贫人口，要逐步同通过正常帮扶有能力稳定脱贫的人口分开，实行分类管理。</p>

<p>要坚持把增加农民收入作为“三农”工作的中心任务，千方百计拓宽农民增收致富渠道。要发展比较优势明显、带动能力强、就业容量大的县域富民产业，多措并举促进农民工稳岗就业，挖掘经营增收和务工增收潜力。要完善联农带农机制，注重把产业增值收益更多留给农民，让农民挑上“金扁担”。要加大惠农富农政策力度，给农民的补贴能增加的还要增加，社会保障水平能提高的还要提高，农村各类资源要素能激活的尽量激活，让农民腰包越来越鼓、日子越过越红火。</p>
<div class="img5">
            <img src="../assets/images/wt42.jpg">
          </div> 
</ul><div class="pag">16/20</div></div>
      <div class="tria1"></div>
    <div class="tria2"></div>
      <div class="back">
        <div class="gest">
        <img src="../assets/images/wt27.png"></div>
        <ul>
          <div class="pho">
            <img src="../assets/images/wt44.png">
          </div>
          <h2>四、依靠科技和改革双轮驱动加快建设农业强国</h2>

<p>建设农业强国，利器在科技，关键靠改革。必须协同推进科技创新和制度创新，开辟新领域新赛道，塑造新动能新优势，加快实现量的突破和质的跃升。</p>

<p>当前，我国农业科技创新整体迈进了世界第一方阵，但农业科技进步贡献率同世界先进水平相比还有不小的差距。我们的资源就那么多，超大规模市场对农产品的需求又不断增长，现在比以往任何时候都更加需要重视和依靠农业科技创新，不仅要立志补上短板弱项，还要立志发挥后发优势、实现“弯道超车”。要紧盯世界农业科技前沿，大力提升我国农业科技水平，加快实现高水平农业科技自立自强。</p>

<p>农业科技创新要着力提升创新体系整体效能，解决好各自为战、低水平重复、转化率不高等突出问题。要以农业关键核心技术攻关为引领，以产业急需为导向，聚焦底盘技术、核心种源、关键农机装备、合成药物、耕地质量、农业节水等领域，发挥新型举国体制优势，整合各级各类优势科研资源，强化企业科技创新主体地位，构建梯次分明、分工协作、适度竞争的农业科技创新体系。要打造国家农业科技战略力量，支持农业领域重大创新平台建设。农业科技创新周期相对较长，要舍得下力气、增投入，给予长期稳定的支持。</p>



<p>农业科技工作要突出应用导向，加快成果转化，把论文写在大地上。农户分散经营仍然是我国农业经营的基本面，农业科技推广任务很重，必须政府和市场协同发力。基层农技推广体系要稳定队伍、提升素质、回归主业，强化公益性服务功能。同时，要鼓励发展各类社会化农业科技服务组织，创新市场化农技推广模式，打通科技进村入户“最后一公里”。</p>

<p>加快建设农业强国，迫切需要改革增动力、添活力。深化农村改革，必须继续把住处理好农民和土地关系这条主线，把强化集体所有制根基、保障和实现农民集体成员权利同激活资源要素统一起来，搞好农村集体资源资产的权利分置和权能完善，让广大农民在改革中分享更多成果。</p>

<p>当前，各地第二轮土地承包正陆续到期，要扎实做好承包期再延长30年的各项工作，确保大多数农户原有承包权保持稳定、顺利延包。有条件的地方可在农民自愿前提下，探索开展农田集中连片整理，解决细碎化问题。发展适度规模经营是现代农业的方向，要支持有条件的小农户成长为家庭农场，支持家庭农场组建农民合作社、合作社根据发展需要办企业，加快健全农业社会化服务体系，把小农户服务好、带动好。土地经营权流转、集中、规模经营要把握好度，不能依靠行政手段强行流转农民土地，人为垒大户、搞不切实际的大规模甚至超大规模经营，防止强行收回农民承包地搞“反租倒包”。</p>


        </ul>
        <div class="pag">17/20</div>
      </div>
    </div>
    <div class="card">
      <div class="font"><div class="gest">
        <img src="../assets/images/wt28.png"></div> 
        <ul>
          <p>要稳慎推进农村宅基地制度改革试点，聚焦保障居住、管住乱建、盘活闲置，在试点中不断探索完善集体所有权、农户资格权、宅基地使用权等权利内容及其分置的实现形式。要深化农村集体经营性建设用地入市试点，完善土地增值收益分配机制，增强改革系统性。</p>

<p>农村集体产权制度改革重点是适应社会主义市场经济要求，构建产权关系明晰、治理架构科学、经营方式稳健、收益分配合理的运行机制，充分利用农村集体自身资源条件、经营能力，探索资源发包、物业出租、居间服务、资产参股等多样化途径发展新型农村集体经济。发展集体经济必须尊重群众意愿、遵循市场规律，不能走“归大堆”的老路子。要健全农村集体资产监管体系，严格控制集体经营风险，坚决遏止新增债务，充分保障集体成员的知情权、参与权、监督权，决不能让集体经济变成少数人的“小金库”。</p>

<p>要顺应城乡融合发展大趋势，破除妨碍城乡要素平等交换、双向流动的制度壁垒，促进发展要素、各类服务更多下乡，率先在县域内破除城乡二元结构。要鼓励社会资本下乡成为农业经营主体，但必须守住经营农业、农民受益的基本要求，防止跑马圈地。要加快农业转移人口市民化，健全常住地提供基本公共服务制度，让能进城愿进城的，更快更好融入城市。同时，要保障进城落户农民土地等合法权益，鼓励依法自愿有偿退出。</p>

<p>推进农村改革，必须保持历史耐心，看准了再推，条件不成熟的不要急于去动。对涉及土地、耕地等农民基本权益特别是改变千百年来生产生活方式的事情，一定要慎之又慎，牢牢守住土地公有制性质不改变、耕地红线不突破、农民利益不受损的底线。</p>
<div class="img5">
            <img src="../assets/images/wt43.jpg">
          </div> 
          </ul>
          <div class="pag">18/20</div></div>
      <div class="tria1"></div>
    <div class="tria2"></div>
      <div class="back">
        <div class="gest">
        <img src="../assets/images/wt27.png"></div>
        <ul>
          <div class="pho">
            <img src="../assets/images/wt44.png">
          </div>
          <h2>五、大力推进农村现代化建设</h2>

<p>农村现代化是建设农业强国的内在要求和必要条件，建设宜居宜业和美乡村是农业强国的应有之义。农业因人类定居而兴，村落因农事活动而聚，乡村不仅是农业生产的空间载体，也是广大农民生于斯长于斯的家园故土。建设农业强国要一体推进农业现代化和农村现代化，实现乡村由表及里、形神兼备的全面提升。</p>

<p>这些年，乡村建设取得一定成效，但农村基础设施仍不完善，公共服务水平不高，欠账还很多。要瞄准“农村基本具备现代生活条件”的目标，组织实施好乡村建设行动，特别是要加快防疫、养老、教育、医疗等方面的公共服务设施建设，提高乡村基础设施完备度、公共服务便利度、人居环境舒适度，让农民就地过上现代文明生活。要对我国城镇化趋势、城乡格局变化进行研判，科学谋划村庄布局，防止“有村无民”造成浪费。乡村建设要充分考虑财力可持续和农民可接受，坚持数量服从质量、进度服从实效，集中力量先抓好普惠性、基础性、兜底性民生建设，优先建设既方便生活又促进生产的项目，标准可以有高有低，但不能缺门漏项。各地村庄格局风貌是历史上顺应地理、气候、人文条件延续而成的，要敬畏历史、敬畏文化、敬畏生态，留住乡风乡韵乡愁。乡村建设是为农民而建，要健全自下而上、农民参与的实施机制，多听群众意见，照顾农民感受。</p>

<p>要完善党组织领导的自治、法治、德治相结合的乡村治理体系，让农村既充满活力又稳定有序。要坚持大抓基层的鲜明导向，推动治理和服务重心下移、资源下沉，推动乡镇赋权扩能，整合力量、提升能力，确保接得住、用得好。要深化党组织领导的村民自治实践，创新乡村治理抓手载体，完善推广积分制、清单制、数字化、接诉即办等务实管用的治理方式。</p>

<p>推进农村现代化，不仅物质生活要富裕，精神生活也要富足。农村精神文明建设要同传承优秀农耕文化结合起来，同农民群众日用而不觉的共同价值理念结合起来，弘扬敦亲睦邻、守望相助、诚信重礼的乡风民风。要加强法治教育，引导农民办事依法、遇事找法、解决问题用法、化解矛盾靠法。农村移风易俗重在常抓不懈，找准实际推动的具体办法，创新用好村规民约等手段，倡导性和约束性措施并举，绵绵用力，成风化俗，坚持下去，一定能见到好的效果。</p>
        </ul>
        <div class="pag">19/20</div>
      </div>
    </div>
    <div class="card">
      <div class="font"><div class="gest">
        <img src="../assets/images/wt28.png"></div> 
        <ul>
          <h2>六、加强党对加快建设农业强国的全面领导</h2>

<p>全面推进乡村振兴、加快建设农业强国，关键在党。必须坚持党领导“三农”工作原则不动摇，健全领导体制和工作机制，为加快建设农业强国提供坚强保证。</p>

<p>五级书记抓乡村振兴是党中央的明确要求，也是加快建设农业强国的有效机制。市县两级更要把“三农”工作作为重头戏，花大精力来抓，特别是县委书记要当好“一线总指挥”，不重视“三农”的书记不是好书记，抓不好农村工作的书记不是称职的书记。要完善考核督查机制，以责任落实推动工作落实、政策落实。</p>

<p>各级党委要加大对涉农干部的培训力度，提高“三农”工作本领，改进工作作风，打造一支政治过硬、适应新时代要求、具有领导农业强国建设能力的“三农”干部队伍。各级党政干部要把调查研究、求真务实作为基本功，不能脱离实际，想当然、拍脑袋；要强化系统观念，统筹处理好两难甚至多难问题，不能“按下葫芦起了瓢”。要树牢群众观点、贯彻群众路线，经常到农民群众家中走一走、看一看，深入群众、扎根群众，问需于民、问计于民，关心群众安危冷暖、急难愁盼，从农民群众的喜怒哀乐中检视我们的工作。</p>

<p>人才是最宝贵的资源，是加快建设农业强国的基础性、战略性支撑。一些农村发展乏力，关键在于缺人才，缺发展引路人、产业带头人、政策明白人。各级党委和政府要坚持本土培养和外部引进相结合，用乡村广阔天地的发展机遇吸引人，用乡村田园宜居的优美环境留住人。要着力培养一批乡村人才，重点加强村党组织书记和新型农业经营主体带头人培训，全面提升农民素质素养，育好用好乡土人才。同时，要引进一批人才，有序引导大学毕业生到乡、能人回乡、农民工返乡、企业家入乡，创造机会、畅通渠道、营造环境，帮助解决职业发展、社会保障等后顾之忧，让其留得下、能创业。要树立阶段性用才理念，不求所有、但求所用。要推动县域内人才打通使用，有计划地把重点培养的党政干部人才和专业技术人才放到农村去，让他们在一线担当作为、锻炼成长。通过多方面努力，着力打造一支沉得下、留得住、能管用的乡村人才队伍，强化全面推进乡村振兴、加快建设农业强国的智力支持和人才支撑。</p>




        </ul>
        <div class="pag">20/20</div></div>
      <div class="back">
        <div class="gest">
        <img src="../assets/images/wt27.png"></div>
        <ul>
          <div class="pho">
            <img src="../assets/images/wt44.png">
          </div>
        <p>农村基层党组织是党在农村全部工作和战斗力的基础。要健全村党组织领导的村级组织体系，把农村基层党组织建设成为有效实现党的领导的坚强战斗堡垒，把村级自治组织、集体经济组织、农民合作组织、各类社会组织等紧紧团结在党组织的周围，团结带领农民群众听党话、感党恩、跟党走。这一轮全国村“两委”集中换届已经全部完成，要全面培训提高村班子领导乡村振兴能力，不断优化带头人队伍，派强用好驻村第一书记和工作队，注重选拔优秀年轻干部到农村基层锻炼成长，充分发挥农村党员先锋模范作用。当前，村党组织、自治组织负责人普遍实现了“一肩挑”，有的还兼任村集体经济组织负责人，对他们的监督管理必须跟上，坚决防止出现“小官巨贪”和无法无天的“村霸”。要推动基层纪检监察组织和村务监督委员会有效衔接，把纪检监察工作向村延伸覆盖。要持续为农村基层干部减负，深入整治形式主义、官僚主义，减少名目繁多、变形走样的考核评估、督查检查等，让基层干部有更多精力为农民办实事。</p>

<p>同志们，农业强国，是拼出来、干出来、奋斗出来的。从现在开始，就要铆足干劲，全面推进乡村振兴，加快农业农村现代化步伐，为加快建设农业强国而努力奋斗。</p>

<p>※这是习近平总书记2022年12月23日在中央农村工作会议上的讲话。</p>
</ul>
<div class="pag">21/20</div>
      </div>
    </div>
    
    
    
    
  </div>
  <!-- <div class="header">
      <h1 id="text">WebMessageTest</h1>
  </div> -->
</template>

<script>
import axios from "axios";
export default {
  props:{
    msg:String
  },
  data(){
    return{
      pageNum:0,
      webData:{},
      loaded:false
    }
  },

  mounted() {
    this.loaded = true;
    this.getData();
    this.getPageInfo()
    // this.keyDown()
    console.log('window',window);
    // console.log(11111111111111);
    // setTimeout(() => {
    //   console.log(22222222222);
      // window.postMessage("SetGestureEventHandler=OnGestureRecognizer");
      // this.OnGestureRecognizer()
      // window.top.chrome.webview.postMessage("11111111111")
      // console.log('window.chrome',window.chrome);
      // console.log('window.chrome.webview',window.chrome.webview);
    // }, 1000);
    window.OnGestureRecognizer= this.OnGestureRecognizer

    window.chrome.webview.postMessage("SetGestureEventHandler=window.OnGestureRecognizer");
  },

  methods: {
   getData(){
      axios.get('/test.json').then(res => {
        // 处理响应
        // console.log("数据:"+res.data);
        let dataObject = res.data // 这里假设获取到的数据是一个 Object 类型的数据
        // console.log(dataObject.data);
        //
        let jsonString = JSON.stringify(dataObject) // 将 Object 类型的数据转换为字符串类型的 JSON 数据
        // console.log(jsonString) // 输出字符串类型的 JSON 数据
        let jsonObject = JSON.parse(jsonString)

        this.webData = jsonObject;
        }).catch(error => {
        console.log(error.response.status)
        console.log(error.response.data)
        console.log(error.response.headers)
      })
    },
    getPageInfo(){
     
      // 调整z-index的值，让页面顺序显示，默认第一页在上面
      var runPage = document.querySelectorAll('.card');
      console.log('runPage',runPage);
      for (var i = 0,len=runPage.length;i<len;i++) {
        runPage[i].style.zIndex=len-i
        
      }

      // 模拟翻书
      // setTimeout(() => {
      //   runPage[0].classList.add('left')
      // }, 1000);

      // this.keyDown()

      document.onkeydown =  (e) => {
        
        //事件对象兼容
        let e1 = e || event || window.event || arguments.callee.caller.arguments[0]
        //键盘按键判断:左箭头-37;上箭头-38；右箭头-39;下箭头-40
        //左
        
     }


    },
    OnGestureRecognizer(s) {
      var runPage = document.querySelectorAll('.card');
      console.log('runPage',runPage);
      for (var i = 0,len=runPage.length;i<len;i++) {
        runPage[i].style.zIndex=len-i
        
      }
       
      console.log('OnGestureRecognizer:' +
      s);
      this.message = s;
      if(s === 'BROWSER_FORWARD'){
       
          
          if(this.pageNum>0){
            this.pageNum--
            runPage[this.pageNum].classList.remove('left')
            runPage[this.pageNum].classList.add('right')
            setTimeout(()=> {
              runPage[this.pageNum].classList.remove('right')
            }, 500);
           
          }else{
            console.log('已经是到第一页了');
          }

           console.log('this.pageNum',this.pageNum);
          
        
          }else if (s === 'BROWSER_BACK'){
      
        
          if(this.pageNum<len){
            runPage[this.pageNum].classList.add('left')
            this.pageNum++
          }else{
            console.log('已经是到最后一页了');
          }

          console.log('this.pageNum',this.pageNum);
        
      }
    
       
    }
  },
}
</script>


<style>
  @import '../test/test.css'
</style>